import React from 'react';
import { Box } from '@mui/material';
import MessageText, { Status } from '../MessageText/MessageText';
import BackButtonLayout from '../../layouts/BackButtonLayout/BackButtonLayout';

//define type fallback error
type FallbackErrorContentProps = {
    error?: Error;
    resetErrorBoundary: () => void;
};

/**
 * FallbackErrorContent component is used to catch error and display content error message.
 */
const FallbackErrorContent = ({ resetErrorBoundary }: FallbackErrorContentProps) => {
    /**
     * Call resetErrorBoundary to reset the error boundary and retry the render.
     */
    const handleReset = () => {
        resetErrorBoundary();
    };

    return (
        <BackButtonLayout onNavigate={handleReset}>
            <Box
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <MessageText status={Status.error} text='Something went wrong! Please retry again' />
            </Box>
        </BackButtonLayout>
    );
};

export default FallbackErrorContent;
