import React from 'react';
import CustomDialog from '../CustomDialog';
interface DialogPrintPDFProps {
    open: boolean;
    close: () => void;
    pdfContent: string
}

/**
 * PrintPDFDialog component is used to display the pdf content in dialog component.
 */
const PrintPDFDialog = ({ open, close, pdfContent }: DialogPrintPDFProps) => {
    /**
     * dialogContent is used to render the content of dialog component
     */
    const dialogContent = {
        content: (
            <>
                <object>
                    <embed id='pdfID' width='1200' height='600' src={pdfContent} />
                </object>
            </>
        ),
    };

    /**
     * dialogStyle is used to render the style of dialog component
     */
    const dialogStyle = {
        content: {
            width: '100%',
            maxWidth: '100%',
            padding: '0px',
        },
    };

    return (
        <CustomDialog
            open={open}
            onClose={close}
            title='Print PDF'
            content={dialogContent?.content}
            style={dialogStyle}
        />
    );
};

export default PrintPDFDialog;
