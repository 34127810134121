import { v4 as uuidv4 } from 'uuid';

export function convertBase64ToString(base64: string) {
    return Buffer.from(base64, 'base64').toString('utf8');
}

export function detectDevice(userAgent: string) {
    const userAgentLowerCase = userAgent.toLowerCase();

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgentLowerCase)) {
        return 'mobile';
    } else if (/mac|win/i.test(userAgentLowerCase)) {
        return 'laptop';
    } else {
        return 'unknown';
    }
}

/**
 * @returns a random string of 5 characters
 */
export function randomString() {
    return Math.random().toString(36).slice(2, 7);
}

/**
 * @returns a random integer with number of digits specified by numberDigits
 * @example
 *  randomIntegerString(5) returns a random integer with 5 digits:
 *     12345
 *     00000
 *     00001
 */
export function randomIntegerString(numberDigits: number) {
    return Math.floor(Math.random() * 10 ** numberDigits)
        .toString()
        .padStart(numberDigits, '0');
}

export function generateUUID() {
    return uuidv4();
}

export function splitStringByDash(string: string) {
    return string.split('-');
}

export function convertStringWithDashToObject(string: string, keys: string[]) {
    const stringArray = splitStringByDash(string);
    const object: any = {};
    keys.forEach((key, index) => {
        object[key] = stringArray[index];
    });
    return object;
}

export const typeOf = (value: any) => Object.prototype.toString.call(value).slice(8, -1);
