import React, { useState, useEffect } from 'react';
import CheckboxField from '../components/CheckboxField/CheckboxField';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LocalStorageService from '../services/storage.service';
import PrintPDFDialog from '../components/CustomDialog/PrintPDF/PrintPDFDialog';
import { addQRCodeToPDF } from '../services/printPDF.service';
import { pdfBase64eNVD } from '../models/common';
import { ENVDData, registerConsignmentDLP, registerENVDVC } from '../services/registerPassport.service';
import { toastError, toastSuccess } from '../services/toast.service';

const SellCattle = () => {
    const [cattles, setCattles] = useState<any[]>([]);
    const [selectedNlisidItemList, setSelectedNlisidItemList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadedDLPs, setUpdatedDLPs] = useState<any>({});

    const [encryptedData, setEncryptedData] = React.useState<any>(null);
    const [openDialogPrintPDF, setOpenDialogPrintPDF] = React.useState<boolean>(false);
    const [pdfContent, setPdfContent] = React.useState<string>('');

    const [processState, setProcessState] = React.useState<string>('');

    const cattleByNLISID = LocalStorageService.get('cattleByNLISID');

    useEffect(() => {
        if (cattleByNLISID) {
            const parseValue = JSON.parse(cattleByNLISID);
            setCattles(Object.keys(parseValue));
            setUpdatedDLPs(Object.values(parseValue));
        }
    }, []);

    useEffect(() => {
        if (cattleByNLISID) {
            const parseValue = JSON.parse(cattleByNLISID);
            setCattles(Object.keys(parseValue));
            setUpdatedDLPs(Object.values(parseValue));
        }
    }, [cattleByNLISID]);

    const onChangeCheckBox = (items: any[]) => {
        setSelectedNlisidItemList(items);
    };

    const onClickSellCattle = async () => {
        setLoading(true);

        try {
            const credentialPayload = {
                issuer: process.env.REACT_APP_LIST_ISSUERS!.split(',')[0],
            };
            const eNVDData: ENVDData = {
                livestockIds: selectedNlisidItemList.map((item) => item.value),
            };

            const { eNVDVCUrl, EPCISVCUrl } = await registerENVDVC(eNVDData, credentialPayload);

            setEncryptedData(eNVDVCUrl);

            const consignmentData = constructConsignmentDLPSubject();
            await registerConsignmentDLP(consignmentData, credentialPayload, EPCISVCUrl);
            removeNLISAfterProcessSuccess();
            setProcessState('finished');
            toastSuccess('Successfully sold the cattle.');
        } catch (error) {
            toastError('Error selling the cattle.');
        }
        setLoading(false);
    };

    const constructConsignmentDLPSubject = () => {
        const firstDLP = uploadedDLPs[0];
        const credentialSubject = { ...firstDLP.credentialSubject };
        credentialSubject.herd.NLIS = selectedNlisidItemList.map((item) => item.value);
        return credentialSubject;
    };

    const handleClickPrintPDF = async () => {
        setOpenDialogPrintPDF(true);

        const value = await addQRCodeToPDF(pdfBase64eNVD, encryptedData);
        setPdfContent(value);
    };

    const removeNLISAfterProcessSuccess = () => {
        if (!cattleByNLISID) return;
        const parseValue = JSON.parse(cattleByNLISID);
        selectedNlisidItemList.forEach((key) => delete parseValue[key.value]);
        setSelectedNlisidItemList([]);
        LocalStorageService.set('cattleByNLISID', JSON.stringify(parseValue));
    };

    return (
        <>
            <Box
                sx={{
                    paddingTop: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CheckboxField
                    data={cattles.map((data) => {
                        return {
                            label: data,
                            value: data,
                        };
                    })}
                    onChangeCheckBox={onChangeCheckBox}
                />
                <Box
                    sx={{
                        display: 'flex',
                        marginTop: '24px',
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        onClick={onClickSellCattle}
                        sx={{ ml: 1, mt: 5 }}
                        variant='contained'
                        disabled={selectedNlisidItemList.length === 0}
                    >
                        Sell Cattle
                    </LoadingButton>

                    {processState === 'finished' && (
                        <LoadingButton sx={{ ml: 1, mt: 5 }} onClick={handleClickPrintPDF} variant='contained'>
                            Print
                        </LoadingButton>
                    )}
                </Box>
                <PrintPDFDialog
                    pdfContent={pdfContent}
                    open={openDialogPrintPDF}
                    close={() => setOpenDialogPrintPDF(false)}
                />
            </Box>
        </>
    );
};

export default SellCattle;
