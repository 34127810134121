import React from 'react';
import IssueFormWrap from '../components/IssueFormWrap/IssueFormWrap';
import { CredentialPayload } from '@vckit/core-types';
import { JsonFormData, pdfBase64eNVD } from '../models/common';
import { credentialsIssue } from '../schemas';
import eNVD from '../constants/credentials/eNVD.json';
import LocalStorageService from '../services/storage.service';
import PrintPDFDialog from '../components/CustomDialog/PrintPDF/PrintPDFDialog';
import { addQRCodeToPDF } from '../services/printPDF.service';
import { ENVDData, registerENVDVC } from '../services/registerPassport.service';
import { convertStringWithDashToObject } from '../utils/helpers';

const formName = 'NationalVendorDeclaration';

/**
 * NationalVendorDeclaration component is used to display the schema of NationalVendorDeclaration.
 */
export const NationalVendorDeclaration = () => {
    const [dynamicSchema, setDynamicSchema] = React.useState<any>(null);
    const [encryptedData, setEncryptedData] = React.useState<any>(null);
    const [openDialogPrintPDF, setOpenDialogPrintPDF] = React.useState<boolean>(false);
    const [pdfContent, setPdfContent] = React.useState<string>('');

    React.useEffect(() => {
        if (!dynamicSchema) {
            const schema: any = credentialsIssue[formName].schema;
            const uploadedENVDs = JSON.parse(LocalStorageService.get('uploadedENVDs')!) || [];
            let liveStockIds: any[] = [];

            uploadedENVDs.forEach(({ credentialSubject }: any) => {
                const livestockIds = credentialSubject?.answers?.filter((answer: any) => answer.questionId === '10');
                if (livestockIds) liveStockIds.push(livestockIds);
            });

            liveStockIds = Object.values(
                liveStockIds.flat().reduce((acc: any, cur: any) => {
                    acc[cur.value] = cur;
                    return acc;
                }, {}),
            ).map((item: any, idx: number) => ({ ...item, index: idx }));

            if (liveStockIds.length === 0) {
                return setDynamicSchema(null);
            }

            if (schema?.properties?.sender?.enum)
                schema.properties.sender.enum = [`${eNVD.origin.name}-${eNVD.origin.pic}`];

            if (schema?.properties?.receiver?.enum)
                schema.properties.receiver.enum = [`${eNVD.destination.name}-${eNVD.destination.pic}`];

            if (schema?.properties?.livestockIds?.items?.enum) {
                schema.properties.livestockIds.items.enum = liveStockIds.map((item) => item.value);
            }

            setDynamicSchema(schema);
        }
    }, []);

    const processor = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        const eNVDData: ENVDData = {
            livestockIds: formData['livestockIds'],
            sender: convertStringWithDashToObject(formData['sender'], ['name', 'pic']),
            receiver: convertStringWithDashToObject(formData['receiver'], ['name', 'pic']),
            consignmentNumber: formData['consignmentNumber'],
        };
        const { vc, eNVDVCUrl } = await registerENVDVC(eNVDData, credentialPayload);
        setEncryptedData(eNVDVCUrl);
        return { vc };
    };

    const handleClickPrintPDF = async () => {
        setOpenDialogPrintPDF(true);

        const value = await addQRCodeToPDF(pdfBase64eNVD, encryptedData);
        setPdfContent(value);
    };

    return (
        <>
            {dynamicSchema && (
                <IssueFormWrap
                    formName={formName}
                    processor={processor}
                    dynamicSchema={dynamicSchema}
                    buttonAction={handleClickPrintPDF}
                />
            )}
            <PrintPDFDialog
                pdfContent={pdfContent}
                open={openDialogPrintPDF}
                close={() => setOpenDialogPrintPDF(false)}
            />
        </>
    );
};
